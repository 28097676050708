import Image from 'next/image'
import React from 'react'

type IntroduceBannerProps = {
  title: string
  youtubeUrl: string
  preview: string
  buttonText: string
}

const IntroduceBanner: React.FC<IntroduceBannerProps> = ({
  title,
  youtubeUrl,
  preview,
  buttonText,
}) => {
  return (
    <div className="youtubeArea m-auto pc:flex">
      <div className="pc:mr-6 introducebanner">
        <pre className="mb-3 ">{title}</pre>
        <a className="pc:hidden" href={youtubeUrl} target="_blank" rel="noopener noreferrer">
          <Image className="youtubeImg" src={preview} alt="" width="964" height="538" />
        </a>
        <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
          <p className="youtubeLink sp:mt-3 relative">
            {buttonText}
            <Image
              className="linkImg"
              src="/images/youtube_link.png"
              alt=""
              width="20"
              height="14"
              layout="raw"
            />
            <Image
              className="hoverImg"
              src="/images/youtube_link_hover.png"
              alt=""
              width="20"
              height="14"
              layout="raw"
            />
          </p>
        </a>
      </div>
      <a className="sp:hidden m-auto" href={youtubeUrl} target="_blank" rel="noopener noreferrer">
        <Image className="youtubeImg" src={preview} alt="" width="964" height="538" layout="raw" />
      </a>
    </div>
  )
}

export default IntroduceBanner
