import Image from 'next/image'
import React from 'react'

type Props = {
  title: string
  textList: Array<{
    left?: string
    right?: string
  }>
}

const LpIntroTitle: React.FC<Props> = ({ title, textList }) => {
  return (
    <>
      <div className="col-start-1 col-end-7 font-sans  mt-1 font-bold sougi-intro-overlay-title">
        {title}
      </div>
      {textList.map((text) => {
        return (
          <>
            <div className="col-start-1 col-end-4 px-10 sp:px-2 text-left font-sans items-start">
              {text.left && (
                <div className="grid grid-rows-1 grid-flow-col gap-1 sp:gap-0">
                  <div className="col-span-1 mt-5px ">
                    <Image
                      className="mr-2 align-baseline lp-intro-checkIcon"
                      src="/svg/checkbox.svg"
                      alt=""
                      width="20"
                      height="13"
                      layout="raw"
                    />
                  </div>
                  <div className="col-span-2">{text.left}</div>
                </div>
              )}
            </div>
            <div className="col-start-4 col-end-7 px-10 sp:px-2 font-sans text-left items-start">
              {text.right && (
                <div className="grid grid-rows-1 grid-flow-col gap-1 sp:gap-0">
                  <Image
                    className="mr-2 align-baseline lp-intro-checkIcon"
                    src="/svg/checkbox.svg"
                    alt=""
                    width="20"
                    height="13"
                    layout="raw"
                  />
                  <div className="col-span-2">{text.right}</div>
                </div>
              )}
            </div>
          </>
        )
      })}
      <div className="m-3" />
    </>
  )
}

export default LpIntroTitle
