import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { urlPath } from 'src/utils/lpPath'

const LpContact: React.FC = () => {
  return (
    <div className="contact">
      <p className="text-2xl mb-8">
        導入のご検討・相談は
        <br className="pc:hidden" />
        こちらから
      </p>
      <Link href={urlPath.contact} passHref>
        <a>
          <button>
            <Image
              className="mr-4 align-baseline"
              src="/svg/mail_icon.svg"
              alt=""
              width="20"
              height="13"
              layout="raw"
            />
            お問い合わせ
          </button>
        </a>
      </Link>
    </div>
  )
}

export default LpContact
