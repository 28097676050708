import Image from 'next/image'
import React from 'react'

import ShizutetsuBanner from '@/components/feature/ShizutetsuBanner'
import UrbanBanner from '@/components/feature/UrbanBanner'

const LpUserVoice: React.FC = () => {
  return (
    <>
      <div className="relative section-interview" id="voiceInterview">
        <h1 className="caption" id="voice">
          お客様の声
        </h1>
        <p className="subCaption mb-6">Voice</p>
        <div className="pc:flex pc:items-center container mx-auto">
          <div className="grid grid-cols-1 m-auto md:grid-cols-2 lg:grid-cols-2 gap-10">
            <UrbanBanner />
            <ShizutetsuBanner />
          </div>
        </div>
      </div>
      <div className="section-interview-bottom relative" id="voice">
        <div className="pc:flex pc:items-center">
          <div className="userVoice odd">
            <p className="text-medium mb-3">葬儀社 担当A様</p>
            <span className="leading-relaxed">
              予想以上の反響で驚きました。持病により葬儀の場に出向けない方もいらっしゃったようで、より多くの方に故人を偲ぶ場を設けられたことで遺族の方からも感謝のお言葉を頂くことができました。今後も活用し、積極的に広めていきたいと思います。
            </span>
          </div>
          <Image
            layout="raw"
            className="ml-8 sp:hidden userVoiceImg"
            src="/images/voice/voice01.jpg"
            alt=""
            width="376"
            height="400"
          />
          <Image
            layout="raw"
            className="pc:ml-8 pc:hidden userVoiceImg"
            src="/images/voice/voice01_SP.jpg"
            alt=""
            width="670"
            height="400"
          />
        </div>
        <div className="pc:flex pc:items-center mt-12">
          <Image
            layout="raw"
            className="mr-8 sp:hidden userVoiceImg"
            src="/images/voice/voice02.jpg"
            alt=""
            width="376"
            height="400"
          />
          <div className="userVoice even">
            <p className="text-medium mb-3">オンライン来場者 B様</p>
            <span className="leading-relaxed">
              当日スケジュールが合わず距離も離れているので出向くのを断念していましたがオンラインで参列する事ができ、とても感謝しています。故人の思い出の写真などを見ながら焼香やメッセージを送る事で気持ちの整理がつきました。
            </span>
          </div>
          <Image
            layout="raw"
            className="pc:mr-8 pc:hidden userVoiceImg"
            src="/images/voice/voice02_SP.jpg"
            alt=""
            width="682"
            height="400"
          />
        </div>
        <div className="pc:flex pc:items-center mt-12">
          <div className="userVoice odd">
            <p className="text-medium mb-3">ご遺族 C様</p>
            <span className="leading-relaxed">
              家族のみでもいいのではと思っていましたが、葬儀社より案内を受け「友人の方も参列したいかもしれない」と義兄の葬儀で利用させていただきました。ご友人から届くメッセージなどを読んで、改めて義兄が様々な方に慕われていたことを知れました。利用してよかったです。
            </span>
          </div>
          <Image
            layout="raw"
            className="pc:ml-8 pc:hidden userVoiceImg"
            src="/images/voice/voice03_SP.jpg"
            alt=""
            width="682"
            height="400"
          />
          <Image
            layout="raw"
            className="ml-8 sp:hidden userVoiceImg"
            src="/images/voice/voice03.jpg"
            alt=""
            width="376"
            height="400"
          />
        </div>
      </div>
    </>
  )
}

export default LpUserVoice
