import Image from 'next/image'
import React from 'react'

import IntroduceBanner from '@/components/common/IntroduceBanner'

import LpIntroTitle from './LpIntroTitle'

const backGroundStyle = 'bg-beige text-center pt-8 pb-5 rounded-xl w-1/2 lg:px-5 px-2 lg:py-6 py-3'

const LpSection: React.FC = () => {
  return (
    <>
      <div>
        <h1 className="caption">@葬儀（アット葬儀）とは？</h1>

        <p className="subCaption">About</p>
        <p className="about leading-loose">
          ＠葬儀とは、ご遺族様、参列者様、葬儀社様のさまざ まな想いに応えるサービスです。
          オンライン葬儀機能や供花卸しサービス、相続相談 サービスをご提供しております。
          故人様を想う方たちの“想い”を大切にし、悔いのない
          お見送りを実現することで、未来へ踏み出すためのお 手伝いをいたします。
        </p>
        <div className="sougi-overlay-container">
          <div className="sougi-overlay-inner">
            <div className="sougi-overlay-container">
              <div className={`${backGroundStyle} mr-6 sp:mr-2`} />
              <div className={`${backGroundStyle}`} />
              <div className="sougi-intro-grid-container">
                <div className="grid grid-cols-6 gap-2">
                  <div className="col-start-1 col-end-4">
                    <div className="main-intro">
                      <Image
                        src="/images/icon_ourner.png"
                        alt="@葬儀（アット葬儀）を利用することで、参列者の方々に以下のようなメリットがあります"
                        width="140"
                        height="90"
                        layout="raw"
                      />
                    </div>
                    <h1 className="text-center text-primary text-lg sp:text-sm font-bold whitespace-pre">
                      {`故人様を想う方々 
（喪主様・参列者様）`}
                    </h1>
                  </div>
                  <div className="col-start-4 col-end-7">
                    <div className="main-intro">
                      <Image
                        src="/images/icon_sougisya.png"
                        alt="@葬儀（アット葬儀）を利用することで、喪主・遺族の方々に以下のようなメリットがあります"
                        width={70}
                        height={120}
                        layout="raw"
                      />
                    </div>
                    <h1 className="flex justify-center justify-items-center items-center text-center text-primary text-lg sp:mt-10 sp:text-sm font-bold whitespace-pre h-16 sp:h-8">
                      葬儀社様
                    </h1>
                  </div>
                  <LpIntroTitle
                    title="オンライン葬儀"
                    textList={[
                      {
                        left: 'より多くの人に見送っていただくことができます',
                        right:
                          '様々なご事情で葬儀に参列することができない方々に、お見送りの場を提供することができます',
                      },
                      {
                        left: '供花や供物、お香典、追悼メッセージなどでお悔やみの気持ちを伝えることができます',
                      },
                    ]}
                  />
                  <LpIntroTitle
                    title="供花卸しサービス"
                    textList={[
                      {
                        left: '手軽に故人様のイメージに合った/ゆかりのある花を選び、送ることができます',
                        right:
                          '必要な種類を必要なタイミングで安定して仕入れることで、喪主様・参列者様の要望に応えることができます',
                      },
                    ]}
                  />
                  <LpIntroTitle
                    title="相続相談サービス"
                    textList={[
                      {
                        left: '葬儀だけでなく、相続に関する相談も安心して丸ごと任せることができます',
                        right:
                          '相続の相談サービスも含めてご案内することで、喪主様のご負担を減らし、お役に立つことができます',
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="refer text-center">
          <span className="referCaption text-medium leading-normal mb-4">
            友人・知人にもお別れの機会を
          </span>
          <p className="text-center">
            調査の結果、<span className="text-secondary">約80％の方が葬儀を重要視</span>
            し、参列したいと思われています。
            <br />
            家族葬などの場合にもオンライン葬儀は参列の機会を提供できます。
          </p>
        </div>
        <div className="useScene rounded-lg">
          <div className="bg-beige text-center pt-8 pb-5 rounded-t-xl">
            <span className="text-center text-primary text-2xl sp:text-medium font-bold decorationLine relative">
              オンライン葬儀の
              <br className="pc:hidden" />
              ご利用シーンが増えています
            </span>
            <div className="pc:flex pc:justify-between mt-6 lg:mx-20 mx-10 sp:mx-auto">
              <div className="case bg-secondary text-white m-2">
                <p className="head">直葬</p>
                <p className="lg:text-base text-xs pt-1 leading-5">
                  実際の葬儀が
                  <br />
                  ない場合に
                </p>
              </div>
              <div className="case bg-secondary text-white m-2">
                <p className="head">家族葬</p>
                <p className="lg:text-base text-xs pt-1 leading-5">
                  ご家族の時間を
                  <br />
                  大切にされたい
                  <br />
                  方に
                </p>
              </div>
              <div className="case bg-secondary text-white m-2">
                <p className="head">お別れ会</p>
                <p className="lg:text-base text-xs pt-1 leading-5">
                  葬儀とは別の
                  <br />
                  お別れの場に
                </p>
              </div>
              <div className="case bg-secondary text-white m-2">
                <p className="head">葬儀後</p>
                <p className="lg:text-base text-xs pt-1 leading-5">
                  葬儀後の
                  <br />
                  ご挨拶と一緒に
                </p>
              </div>
            </div>
          </div>
          <div className="bg-primary text-center py-5 rounded-b-xl">
            <p className="text-center text-white sp:text-xs">
              調査の結果、<span className="text-secondary">約80％の方が葬儀を重要視</span>
              し、
              <br className="pc:hidden" />
              参列したいと思われています。
              <br />
              オンライン葬儀は、様々な形で故人との
              <br className="pc:hidden" />
              お別れの場を提供します。
            </p>
          </div>
        </div>
      </div>
      <IntroduceBanner
        title={`＠葬儀（アット葬儀）のご紹介動画`}
        youtubeUrl={'https://www.youtube.com/watch?v=f3F_l6Vn7DM'}
        preview={'/images/youtube/atsougi_movie2.png'}
        buttonText={'動画を見る　>>'}
      />
      <IntroduceBanner
        title={`オンライン葬儀サービス
『@葬儀』がTVで紹介されました`}
        youtubeUrl={'https://youtu.be/mf9cRx-u8Y8'}
        preview={'/images/youtube/atsougi_movie.jpg'}
        buttonText={'放送シーンはこちら'}
      />
    </>
  )
}

export default LpSection
