export const smoothScroll = (e: React.MouseEvent<HTMLAnchorElement>) => {
  const hash = e.currentTarget.hash
  if (hash.startsWith('#')) {
    const $target = document.getElementById(hash.substring(1))
    if ($target) {
      e.preventDefault()
      $target.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
}
