import { urlPath } from 'src/utils/lpPath'

import InterviewBanner from '@/components/common/InterviewBanner'

const ShizutetsuBanner = () => {
  return (
    <InterviewBanner
      title="【お客様インタビュー】"
      customer="しずてつソーサレア様"
      location="(静岡県)"
      previewUrl="/images/interview/shizutetsu/shizutetsu-sosarea.jpg"
      href={urlPath.shizutetsuSosarea}
      buttonText="インタビューを見る"
    />
  )
}

export default ShizutetsuBanner
