import type { NextPage } from 'next'
import Head from 'next/head'
import { useCallback } from 'react'

import LpContact from '@/components/parts/lpPage/LpContact'
import LpFeatures from '@/components/parts/lpPage/LpFeatures'
import LpSection from '@/components/parts/lpPage/LpIntroduce'
import LpMainImage from '@/components/parts/lpPage/LpMainImage'
import LpNav from '@/components/parts/lpPage/LpNav'
import LpQna from '@/components/parts/lpPage/LpQna'
import LpUserVoice from '@/components/parts/lpPage/LpUserVoice'
import { smoothScroll } from '@/utils/smoothScroll'

const Home: NextPage = () => {
  const getSmoothScroll = useCallback(smoothScroll, [])
  const toTop = useCallback(() => window.scrollTo({ top: 0, behavior: 'smooth' }), [])
  return (
    <>
      <Head>
        <title>
          オンラインによる葬儀参列や香典のオンライン決済ができるサービスアット葬儀（@葬儀）｜オンライン葬儀
        </title>
      </Head>
      <main>
        <div className="newLP" id="top">
          <LpNav toScroll={getSmoothScroll} />
          <LpMainImage />
          <div className="body">
            <div className="section" id="about">
              <LpSection />
              <LpFeatures />
            </div>
            <LpContact />
            <LpUserVoice />
            <LpQna toTop={toTop} smoothScroll={getSmoothScroll} />
            <LpContact />
          </div>
        </div>
      </main>
    </>
  )
}

export default Home
