import Link from 'next/link'
import React from 'react'

type CircleLPInterviewProps = {
  href: string
  upText?: string
  downText?: string
  bannerText?: string
}

const CircleLPInterview: React.FC<CircleLPInterviewProps> = ({
  href,
  upText,
  downText,
  bannerText,
}) => {
  return (
    <Link href={href} passHref>
      <a className="LP_head_interview">
        <div className="circleLPInterview-circle">
          <div className="circleLPInterview-part-color" />
          <div className="circleLPInterview-circleUp">
            <div className="circleLPInterview-up-text">
              <pre>{upText}</pre>
            </div>
            <div className="circleLPInterview-down-text">
              <pre>{downText}</pre>
            </div>
            <div className="circleLPInterview-banner-container">
              <div className="circleLPInterview-banner">{bannerText}</div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default CircleLPInterview
