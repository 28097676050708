import React from 'react'

import Features from '@/components/common/Features'

const LpFeatures: React.FC = () => {
  return (
    <div className="pc:pt-24 sp:pt-16" id="features">
      <h1 className="caption">
        @葬儀（アット葬儀）
        <br className="pc:hidden" />
        の主な機能
      </h1>
      <p className="subCaption">Features</p>
      <ul>
        <div className="pc:flex justify-between mb-12">
          <Features
            width={150}
            height={612}
            count="01"
            title="葬儀参列"
            content={`WEB葬儀場を設置し、
    故人の遺影や思い出のお写真、
    葬儀の模様などがご覧いただけます。
    「ライブ配信」だけではない
    追悼のページが作成できます。`}
          />
          <Features
            width={150}
            height={612}
            count="02"
            title="訃報案内"
            content={`訃報をメールやSNSなど
    日常の連絡手段で送れます。
    受け取った方も案内確認がしやすく、
    供花や供物などの注文も可能です。`}
          />
          <Features
            width={150}
            height={612}
            title="思い出写真共有"
            count="03"
            content={`実際の葬儀に参列できない方へも、
            祭壇や会場などの葬儀の様子、
            故人の思い出を静止画・動画でご覧になっていただけます`}
          />
        </div>
        <div className="pc:flex justify-between mb-12">
          <Features
            width={150}
            height={612}
            count="04"
            title={
              <>
                香典<span className="text-base">（オンライン決済）</span>
              </>
            }
            content={`参列者様がクレジットカード
            決済することで、
            お香典を喪主様にお届けします。
            現金管理のリスクや、
            集計作業などは不要です。`}
          />
          <Features
            width={150}
            height={612}
            count="05"
            title="追悼メッセージ"
            content={`喪主・ご遺族にメッセージと
            画像の送信ができます。
            葬儀場でお声をかけるように、
            お悔やみの言葉や、故人の
            思い出話などをお届けできます。
            `}
          />
          <Features
            width={150}
            height={612}
            count="06"
            title="供花・供物注文"
            content={`訃報を受け取った参列者様から、
            供花や供物の注文を
            直接受け付けることが可能です。
            FAXが自宅にない方なども
            注文がしやすくなります。`}
          />
        </div>
        <div className="pc:flex justify-between mb-12">
          <Features
            width={384}
            height={200}
            count="07"
            title={'供花卸しサービス'}
            content={`今までの品質以上の供花や祭壇を、
            より安価に葬儀社様にご提供することが可能です。
            必要な種類の花を必要なタイミングで安定して
            仕入れることができます。`}
          />
          <Features
            width={384}
            height={200}
            count="08"
            title="相続相談サービス"
            content={`相続が必要な方がスムーズにご相談できる
            窓口をご用意いたします。
            喪主様の「相続相談したい」というお悩みに対しても
            お応えすることが可能となります。`}
          />
        </div>
      </ul>
    </div>
  )
}

export default LpFeatures
