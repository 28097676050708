import Image from 'next/image'
import React from 'react'

type Qna = {
  id: string
  question: string
  answer: string
}

type Props = {
  title: string
  qnaList?: Qna[]
  isOpen: string
  name: string
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const QnaList: React.FC<Props> = ({ name, title, onClick, isOpen, qnaList }) => (
  <React.Fragment>
    <p className="mb-8 faqCaption" id={name}>
      {title}
    </p>
    {React.Children.toArray(
      qnaList &&
        qnaList.map((qna: Qna) => (
          <div key={qna.id} className="flex items-center borderLightGray ">
            <div
              className={`flex flex-col representative_QandA w-full ${
                isOpen === qna.id ? 'open' : 'notOpen'
              }`}
            >
              <div
                id={qna.id}
                className="flex cursor-pointer items-center representative_Q"
                onClick={onClick}
              >
                <Image
                  layout="raw"
                  className="w-6"
                  src="/images/Q_icon.png"
                  alt="Q"
                  width="52"
                  height="82"
                />
                <p className="text-purple ml-6 mr-12">{qna.question}</p>
              </div>
              <div
                className={`flex items-center representative_A ${
                  isOpen === qna.id ? '' : 'hidden'
                }`}
              >
                <Image
                  layout="raw"
                  className="w-6"
                  src="/images/A_icon.png"
                  alt="A"
                  width="52"
                  height="82"
                />
                <p className="ml-6">{qna.answer}</p>
              </div>
            </div>
          </div>
        ))
    )}
  </React.Fragment>
)

export default QnaList
