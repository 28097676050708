import React from 'react'

import QnaList from '@/components/common/QnaList'
import { ATTENDEE_QNA, BEREAVED_QNA, FUNERAL_QNA } from '@/model/QnaModel'

type LpQnaProps = {
  toTop: () => void
  smoothScroll: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const LpQna: React.FC<LpQnaProps> = ({ toTop, smoothScroll }) => {
  const [isOpen, setIsOpen] = React.useState('notOpen')
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      String(e.currentTarget.id) === isOpen ? setIsOpen('notOpen') : setIsOpen(e.currentTarget.id)
    },
    [isOpen]
  )
  return (
    <>
      <div className="section-interview-bottom relative">
        <h1 id="faq" className="caption">
          よくあるご質問
        </h1>
        <p className="subCaption">FAQ</p>
        <div className="text-center">
          <a className="faqLink" href="#admin" onClick={smoothScroll}>
            葬儀社様向け
          </a>
          <a className="faqLink pc:ml-4" href="#bereaved" onClick={smoothScroll}>
            ご遺族様向け
          </a>
          <a className="faqLink pc:ml-4" href="#attendee" onClick={smoothScroll}>
            参列者様向け
          </a>
        </div>
        <QnaList
          name="admin"
          title="葬儀社様向け"
          qnaList={FUNERAL_QNA}
          isOpen={isOpen}
          onClick={handleClick}
        />
        <QnaList
          name="bereaved"
          title="ご遺族様向け"
          qnaList={BEREAVED_QNA}
          isOpen={isOpen}
          onClick={handleClick}
        />
        <QnaList
          name="attendee"
          title="参列者様向け"
          qnaList={ATTENDEE_QNA}
          isOpen={isOpen}
          onClick={handleClick}
        />

        <button className="toTop" onClick={toTop}>
          TOP
        </button>
      </div>
    </>
  )
}

export default LpQna
