import { css } from '@emotion/react'
import throttle from 'lodash.throttle'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { urlPath } from 'src/utils/lpPath'

type LpNavProps = {
  toScroll: (e: React.MouseEvent<HTMLAnchorElement>) => void
  isShowSubMenu?: boolean
}

type ScrollType = {
  position: number
  direction: 'up' | 'down'
}

const nextDirection = (
  prePositionState: {
    position: number
    direction: 'up' | 'down'
  },
  nextPosition: number
) => {
  if (Math.abs(prePositionState.position - nextPosition) > 25) {
    if (prePositionState.position > nextPosition) {
      return 'up'
    }
    return 'down'
  }
  return prePositionState.direction
}

const LpNav: React.FC<LpNavProps> = ({ toScroll, isShowSubMenu = true }) => {
  const router = useRouter()
  const [scrollState, setScrollState] = React.useState<ScrollType>({
    position: 0,
    direction: 'up',
  })

  React.useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    const handleScroll = throttle(() => {
      setScrollState((prevScrollState) => {
        const nextPosition = window.pageYOffset
        const direction = nextDirection(prevScrollState, nextPosition)
        if (prevScrollState.direction === direction) {
          // スクロール方向が同じならstateのインスタンス変えない。render走らせない。
          prevScrollState.position = nextPosition
          return prevScrollState
        }

        // スクロール方向変わればstateのインスタンスを変え、renderを走らせる。
        return {
          direction: direction,
          position: nextPosition,
        }
      })
    }, 100)

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return (
    <>
      <div
        id="navbarPC"
        className="flex justify-between sp:hidden"
        style={{ top: scrollState.direction === 'down' ? -123 : 0 }}
      >
        <Image
          src="/images/logo/atsougi_logo.png"
          alt="@葬儀（アット葬儀）ロゴ"
          width="120"
          height="55"
          layout="raw"
          css={css`
            cursor: pointer;
          `}
          onClick={() => {
            router.push('/')
          }}
        />
        <div>
          <Link href="/#about">
            <a className="navHover" onClick={toScroll}>
              @葬儀とは
            </a>
          </Link>
          <Link href="/#features">
            <a className="navHover" onClick={toScroll}>
              主な機能
            </a>
          </Link>
          <Link href="/#voice">
            <a className="navHover" onClick={toScroll}>
              お客様の声
            </a>
          </Link>
          <Link href="/#faq">
            <a className="navHover" onClick={toScroll}>
              よくあるご質問
            </a>
          </Link>
          <Link href={urlPath.contact}>
            <a>
              <button>
                <Image
                  layout="raw"
                  className="mr-4"
                  src="/svg/mail_icon.svg"
                  alt="お問い合わせリンク"
                  width="20"
                  height="13"
                />
                お問い合わせ
              </button>
            </a>
          </Link>
        </div>
      </div>
      <div
        id="navbarSP"
        className="pc:hidden"
        style={{ top: scrollState.direction === 'up' ? 0 : -130 }}
      >
        <div className="flex justify-between items-center">
          <Image
            layout="raw"
            src="/images/logo/atsougi_logo.png"
            alt="@葬儀（アット葬儀）ロゴ"
            width="105"
            height="48"
          />
          <div className="flex">
            <Link href="/#faq" passHref>
              <a onClick={toScroll}>
                <Image
                  layout="raw"
                  className="mr-4"
                  src="/images/FAQ_icon.png"
                  alt="FAQリンク"
                  width="44"
                  height="44"
                />
              </a>
            </Link>
            <Link href={urlPath.contact}>
              <a>
                <Image
                  src="/images/mail_icon.png"
                  layout="raw"
                  alt="お問い合わせリンク"
                  width="44"
                  height="44"
                />
              </a>
            </Link>
          </div>
        </div>
        {isShowSubMenu && (
          <div className="bg-secondary flex justify-evenly">
            <Link href="/#about" onClick={toScroll}>
              <a>@葬儀とは</a>
            </Link>
            <Link href="/#features" onClick={toScroll}>
              <a>主な機能</a>
            </Link>
            <Link href="/#voice" onClick={toScroll}>
              <a>お客様の声</a>
            </Link>
          </div>
        )}
      </div>
    </>
  )
}

export default LpNav
