import Image from 'next/image'
import React from 'react'

type LpFeaturesProps = {
  title: React.ReactNode
  count: string
  content: string
  width?: number
  height?: number
}

const LpFeatures: React.FC<LpFeaturesProps> = ({ title, count, content, width, height }) => {
  return (
    <li className="gridItem text-center">
      <h2 className="top_h2 pc:text-left mb-4">
        {title}
        <Image
          className="voiceNumber"
          src={`/images/features/${count}.png`}
          alt="1個目の機能"
          width={83}
          height={113}
          layout="raw"
        />
      </h2>
      <Image
        className="sp:hidden"
        src={`/images/features/functions${count}.png`}
        alt=""
        width={width}
        height={height}
        layout="raw"
      />
      <p className="pc:text-left leading-6 mt-4 sp:mb-6 text-sm keep-all">{content}</p>
      <Image
        className="pc:hidden"
        src={`/images/features/functions${count}.png`}
        alt=""
        width={width}
        height={height}
        layout="raw"
      />
    </li>
  )
}

export default LpFeatures
