import { urlPath } from 'src/utils/lpPath'

import InterviewBanner from '@/components/common/InterviewBanner'

const UrbanBanner = () => {
  return (
    <InterviewBanner
      title="【お客様インタビュー】"
      customer="アーバンゲート様"
      location="(静岡県)"
      previewUrl="/images/interview/urbanGate/urban-gate.png"
      href={urlPath.urbanGate}
      buttonText="インタビューを見る"
    />
  )
}

export default UrbanBanner
