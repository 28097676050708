import Image from 'next/image'

import CircleLPInterview from '@/components/common/CircleLPInterview'
import { urlPath } from '@/utils/lpPath'

const LpMainImage = () => {
  return (
    <>
      <div className="relative">
        <Image
          className="PC_sample"
          src="/images/PC_sample.png"
          alt="葬儀のPC画面サンプル"
          width={400}
          height={230}
          layout="raw"
        />
        <Image
          className="SP_sample"
          src="/images/SP_sample.png"
          alt="葬儀のスマホ画面サンプル"
          width={130}
          height={240}
          layout="raw"
        />
        <div className="sp:hidden">
          <Image className=" LP_head" src="/images/LP_head.jpg" alt="" width={2732} height={1564} />
        </div>
        <div className="pc:hidden mt-20">
          <Image
            className="LP_head"
            src="/images/LP_head_SP.jpg"
            alt=""
            width={750}
            height={1038}
          />
        </div>
        <div className="sp:hidden">
          <p className="headText">
            お別れの機会を
            <br />
            もっと多くの方へ
          </p>
          <p className="headSubText">
            大切な方との最後のお別れの機会を
            <br />
            オンラインサービスで実現します
          </p>
        </div>
        <CircleLPInterview
          href={urlPath.urbanGate}
          upText={`お客様
インタビュー`}
          downText={`アーバン
ゲート様`}
          bannerText="掲載中!"
        />
        <div className="whiteBox" />
      </div>
    </>
  )
}

export default LpMainImage
