import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

type InterviewBannerProps = {
  href: string
  title: string
  customer: string
  location: string
  buttonText: string
  previewUrl: string
}

const InterviewBanner: React.FC<InterviewBannerProps> = ({
  href,
  title,
  customer,
  location,
  buttonText,
  previewUrl,
}) => {
  return (
    <div className="interviewBanner m-auto pc:flex pc:justify-around">
      <div className="pc:mr-6">
        <p className="normalText">{title}</p>
        <p>{customer}</p>
        <p className="smallText text-right mb-3">{location}</p>
        <div className="pc:hidden text-center">
          <Image
            className="interviewImage"
            src={previewUrl}
            alt=""
            width="195"
            height="134"
            layout="raw"
          />
        </div>
        <Link href={href} passHref>
          <a>
            <p className="interviewLink sp:mt-3 relative">{buttonText}</p>
          </a>
        </Link>
      </div>
      <Image
        className="sp:hidden interviewImage"
        src={previewUrl}
        alt=""
        width="195"
        height="134"
        layout="raw"
      />
    </div>
  )
}

export default InterviewBanner
