export const FUNERAL_QNA = [
  {
    id: 'funeral01',
    question: '利用には料金がかかりますか？',
    answer: 'システム利用料がかかります。詳しくは［お問い合わせ］ページよりお問い合わせ下さい。',
  },
  {
    id: 'funeral02',
    question: '申し込みから利用開始までどのくらいかかりますか？',
    answer: '最短で当日よりご利用いただけます。',
  },
  {
    id: 'funeral03',
    question: 'システムや機材などを購入する必要はありますか？',
    answer:
      'パソコンやタブレットなどがあればご利用いただけます。ライブ配信をご希望の場合はカメラなどをご用意いただく必要がございますが、推奨品などはございますのでお問い合わせください。',
  },
  {
    id: 'funeral04',
    question: '供花／供物の注文を受けた際に手数料を支払う必要はありますか？',
    answer: '基本プラン内でのご利用であれば、手数料や追加料金無しでご利用いただけます。',
  },
  {
    id: 'funeral05',
    question: 'ネット操作に不安があります。',
    answer:
      'インターネットやパソコンをお使いであれば簡単に操作できます。マニュアルのご用意やお電話などでのサポートも行っております。',
  },
]

export const BEREAVED_QNA = [
  {
    id: 'Bereaved01',
    question: 'ライブ配信サービスですか？',
    answer:
      'ライブ配信も可能ですが、祭壇などの写真や故人の思い出写真なども登録でき、参列者様からのメッセージなども受け取れます。実際の葬儀に参列できない方、故人とご縁のあった皆様にお別れの場を提供できるサービスです。',
  },
  {
    id: 'Bereaved02',
    question: 'どんな方が利用されていますか？',
    answer:
      '故人のご年齢、地域を問わず様々な方が利用されています。家族葬を行う方は親族以外へのご案内、故人がご高齢の場合もご友人達へ最後のご挨拶として活用頂いております。',
  },
  {
    id: 'Bereaved03',
    question: 'オンラインで誰が参列してくれたかわかりますか？',
    answer:
      'オンライン葬儀上で記帳を行っていただけます。記帳情報は喪主様管理画面より確認、ダウンロードが可能です。',
  },
  {
    id: 'Bereaved04',
    question: 'オンライン葬儀サービスは個人で利用できますか？',
    answer:
      '本サービスは法人様向けサービスとなります。サービスを導入されている葬儀社様にお問い合わせ下さい。',
  },
]

export const ATTENDEE_QNA = [
  {
    id: 'Attendee01',
    question: 'オンライン葬儀参列で何ができますか？',
    answer:
      '実際の葬儀のライブ配信、葬儀写真、故人の思い出のお写真などをご覧いただけます。また、ご遺族へのメッセージ、画像の送信や、お香典も送れます。',
  },
  {
    id: 'Attendee02',
    question: 'オンライン参列するのにアプリダウンロードや会員登録などは必要ですか？',
    answer: '必要ありません。喪主・ご遺族より共有頂いたURLよりオンライン葬儀場に入場ください。',
  },
  {
    id: 'Attendee03',
    question: '実際の葬儀日時以外でも参列できますか？',
    answer: 'オンライン葬儀公開期間内であればご覧いただけます。',
  },
  {
    id: 'Attendee04',
    question: 'お香典の支払いをキャンセルしたい。',
    answer:
      'クレジットカード決済後のキャンセルはお受けいたしかねますので、あらかじめご了承ください。',
  },
]
